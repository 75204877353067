/* HTMX loader */
import htmx from "htmx.org";

/* Expose htmx global */
window.htmx = window.htmx || htmx;

/* Add any HTMX configuration here */
window.htmx.config.allowEval = false;
window.htmx.config.includeIndicatorStyles = false;
window.htmx.config.scrollBehavior = "smooth";
window.htmx.config.inlineScriptNonce = document.currentScript.nonce || "";
